import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

export function Logo() {
  return (
    <Link className='logo' to='/'>
      <img src='https://cdn.linearfox.com/assets/img/logo/512/LinearFoxResearch.webp' alt='Linear Fox Logo' style={{ width: '5rem' }} />
    </Link>
  );
}
