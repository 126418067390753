import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const themes = {
  DEFAULT: 'default',
  LINK: 'link',
};

export function Button({
  type, label, href, icon: ButtonIcon, onClick,
}) {
  return type === themes.LINK
    ? (
      <a className='button' href={href} data-theme={type}>
        { label }
        { ButtonIcon && <ButtonIcon className='button__icon' /> }
      </a>
    )
    : (
      <button onClick={onClick} type='button' className='button' data-theme={type}>
        { label }
        { ButtonIcon && <ButtonIcon className='button__icon' /> }
      </button>
    );
}

Button.propTypes = {
  type: PropTypes.oneOf(Object.values(themes)),
  label: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  href: '#',
  label: null,
  type: themes.DEFAULT,
  icon: undefined,
  onClick: undefined,
};
