import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

export function Navigation({ list }) {
  return (
    <nav className='navigation'>
      <ul className='navigation__list'>
        { list.map(({ link, label, id }) => (
          <li className='navigation__link' key={id}>
            <a className='link' href={link}>{ label }</a>
          </li>
        )) }
      </ul>
    </nav>
  );
}

Navigation.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    id: PropTypes.number,
  })).isRequired,
};

/* footer */

export function NavigationFooter({ list }) {
  return (
    <nav className='navigationfooter'>
      <ul className='navigation__list'>
        { list.map(({ link, label, id }) => (
          <li className='navigation__link' key={id}>
            <a className='link' href={link}>{ label }</a>
          </li>
        )) }
      </ul>
    </nav>
  );
}

NavigationFooter.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    id: PropTypes.number,
  })).isRequired,
};
