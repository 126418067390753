import React from 'react';
import './style.scss';

export function Contacts() {
  return (
    <div className='contacts'>
      <a className='outside-link' href='mailto:support@linearfox.com'>support@linearfox.com</a>
      <a className='outside-link' href='http://twitter.com/linear_fox'>@linear_fox</a>
    </div>
  );
}
