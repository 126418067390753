import React from 'react';
import './style.scss';

import { useSpring } from 'react-spring';
import { Title } from '../Title/Title';
import { Button } from '../Button/Button';
import { Group } from '../Group/Group';
import { ReactComponent as LinkSVG } from '../../images/linkSvg.svg';

export function MainBlock() {
  const titleAnim = useSpring({
    opacity: 1,
    transform: 'translateX(0px)',
    from: { opacity: 0, transform: 'translateX(-40px)' },
  });

  return (
    <section className='main'>
      <Title titleStyle={titleAnim} type='h1' text={'The Upcoming \n Research Platform \n for the Linear Fox'} />
      <br />
      <Group oriented='horizontal' className='main__more'>
        <center>
          <Button icon={LinkSVG} label='Follow us on X' type='link' href='https://x.com/Linear_Fox' />
        </center>
      </Group>
    </section>
  );
}
