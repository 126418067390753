import React from 'react';
import './style.scss';
import { MainBlock } from '../../components/Main/Main';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';

export function Home() {
  return (
    <>
      <Header />
      <MainBlock />
      <Footer />
    </>
  );
}
