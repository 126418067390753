import React from 'react';
import './style.scss';
import { Logo } from '../Logo/Logo';
import { Contacts } from '../Contacts/Contacts';
import { PageNavigationFooter } from '../PageNavigation/PageNavigation';
import { Group } from '../Group/Group';

export function Footer() {
  return (
    <footer className='footer'>
      <Logo color='var(--color-grey)' />
      <p className='copyright'>
        © Linear Fox 2024
        <br />
        ALL RIGHTS RESERVED.
      </p>
      <PageNavigationFooter />
      <Group className='contact' oriented='vertical'>
        <Contacts />
      </Group>
    </footer>
  );
}
